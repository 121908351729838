document.addEventListener('DOMContentLoaded', () => {
    // Écoute des clics dans tout le document
    document.addEventListener('click', (event) => {
      // Vérifie si l'élément cliqué est dans le #blockcart-modal
      const modal = document.getElementById('blockcart-modal');
      if (modal && modal.contains(event.target)) {
        // Ne pas rafraîchir si l'élément cliqué est une balise <a>
        if (event.target.tagName === 'A') {
          return;
        }
        // Sinon, rafraîchir la page
        location.reload();
      }
    });
  });