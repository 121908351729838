document.addEventListener('DOMContentLoaded', () => {
    // Sélectionner tous les éléments ayant l'attribut data-type="extras"

    replace_selections();

    document.addEventListener('click', (event) => {
        const interval = setInterval(() => {
            if (document.querySelectorAll('[data-type="extras"]').length > 0) {
                replace_selections();
                clearInterval(interval); // Arrêter l'intervalle une fois que les éléments sont chargés
            }
        }, 800);
    });

    function replace_selections() {
        const elements = document.querySelectorAll('[data-type="extras"]');
        // Masquer le premier paragraphe de chaque élément trouvé
        elements.forEach(element => {
            const firstParagraph = element.querySelector('p');
            if (firstParagraph) {
                firstParagraph.style.display = 'none';
            }

            const firstA = element.querySelector('a');
            if (firstA) {
                firstA.textContent = firstA.textContent.replace('Discounted', 'En réduction');
            }
        });

        // Sélectionner l'élément avec l'id js-active-search-filters et vérifier s'il contient la classe filter-block
        const activeSearchFilters = document.querySelector('.filter-block');

        if (activeSearchFilters) {
            // Rechercher les nœuds de texte spécifiques
            const nodes = activeSearchFilters.childNodes;

            nodes.forEach(node => {
                if (node.nodeType === Node.TEXT_NODE) {
                    // Remplace "Selections : Discounted" par "Remisé"
                    const text = node.textContent.trim();

                    console.log(text);
                    if (text.includes('Discounted')) {
                        node.textContent = 'En réduction';
                    }
                }
            });
        }
    }
});